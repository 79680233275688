import { Selector } from "@ngxs/store";

import { PermitListItem } from "../models/permit-list-item.model";
import { PreparedPermitListItem } from "../models/prepared-permit-list-item.model";
import { PermitListState, PermitListStateModel } from "./permit-list.state";

export class PermitListSelectors {

    @Selector([PermitListState])
    public static ongoingPermits(state: PermitListStateModel): PermitListItem[] | undefined {
        return state?.ongoingPermits;
    }

    @Selector([PermitListState])
    public static plannedPermits(state: PermitListStateModel): PermitListItem[] | undefined {
        return state?.plannedPermits;
    }

    @Selector([PermitListState])
    public static completedPermits(state: PermitListStateModel): PermitListItem[] | undefined {
        return state?.completedPermits;
    }

    @Selector([PermitListState])
    public static preparedPermits(state: PermitListStateModel): PreparedPermitListItem[] | undefined {
        return state?.preparedPermits;
    }
}
