<ng-container *ngIf="(haveAccessToPermit$ | async) === true">
    <div class="root" *transloco="let t; read:'permit'">
        <pmt-sign-permits-notice class="root__signatures"></pmt-sign-permits-notice>
        <pmt-subscription-invitations class="root__invitations"></pmt-subscription-invitations>

        <ng-container *ngIf="(isHotworkerOrPremiumIssuer$ | async) === true">
            <div class="root__create" (click)="create()">{{ t("createNew") }}</div>
            <div class="root__prepare" (click)="preparePermit()">{{ t("prepareWithCode") }}</div>
            <a class="root__prepared-permits" *ngIf="hasActiveSubscription$ | async" [routerLink]="PermitPaths.PreparedPermits">{{ t("getPreparedPermit") }}</a>
            <div *ngIf="(showCreateDemoButton$ | async) === true" class="root__create-demo-anchor" (click)="createDemo()">{{ t("createNewDemo") }}</div>
        </ng-container>

        <ng-container *ngIf="(isHotworkerOrPremiumIssuer$ | async) === false && (showCreateDemoButton$ | async) === true">
            <div class="root__create-demo" (click)="createDemo()">{{ t("createNewDemo") }}</div>
        </ng-container>

        <div class="root__tab-menu">
            <div class="item" [class.item--active]="selectedListType === PermitListType.Ongoing" (click)="changeTab(PermitListType.Ongoing)"
                    [innerHtml]="t('list.ongoing')"></div>
            <div class="item" [class.item--active]="selectedListType === PermitListType.Planned" (click)="changeTab(PermitListType.Planned)"
                    [innerHtml]="t('list.planned')"></div>
            <div class="item" [class.item--active]="selectedListType === PermitListType.Completed" (click)="changeTab(PermitListType.Completed)"
                    [innerHtml]="t('list.completed')"></div>
        </div>

        <pmt-permit-list *ngIf="selectedListType === PermitListType.Ongoing" [permits]="ongoingPermits$ | async"></pmt-permit-list>
        <pmt-permit-list *ngIf="selectedListType === PermitListType.Planned" [permits]="plannedPermits$ | async"></pmt-permit-list>

        <ng-container *ngIf="selectedListType === PermitListType.Completed">
            <pmt-permit-list [permits]="completedPermits$ | async"></pmt-permit-list>
        </ng-container>
    </div>
</ng-container>

<pmt-no-access *ngIf="(haveAccessToPermit$ | async) === false"></pmt-no-access>
