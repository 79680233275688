import { Injectable } from "@angular/core";
import { ConceptType, PersonRole } from "@ha/data/basic";
import { BaseValidationResult } from "@ha/ui/forms";
import { Action, State, StateContext } from "@ngxs/store";
import produce from "immer";
import { Observable, of, tap } from "rxjs";

import { CertificateStatus } from "../../permit-create/models/certificate-status.enum";
import { PermitPerson, PermitPersonWithStatus } from "../../shared/models/permit-person.model";
import { CreateCertificateAndPersonCommand, CreateCertificateAndPersonForm, CreateCertificateAndPersonResponse } from "../models/create-certificate-person.model";
import { AddStagedPersonsToPermitCommand, AddStagedPersonsToPermitResponse, FindCertifiedPersonQuery, FindCertifiedPersonResult } from "../models/permit-team-commands.model";
import { PermitTeam } from "../models/permit-team.model";
import { PermitTeamService } from "../services/permit-team.service";
import { PermitTeamActions } from "./permit-team.action";

export interface PermitTeamStateModel {
    recentTeamMembers: PermitPersonWithStatus[];
    recentTeamMembersFullyLoaded: boolean;
    search: PermitTeamSearch;
    stagedPersons: PermitPerson[];
    stageRole: PersonRole;
    team: PermitTeam;
    newPersonForm: {
        creating: boolean;
        model: CreateCertificateAndPersonForm;
        dirty: boolean;
        status: string;
        errors: unknown;
    };
    newPersonFormResponse: CreateCertificateAndPersonResponse;
}

export interface PermitTeamSearch {
    errorMessage: string;
    query: FindCertifiedPersonQuery;
    person: PermitPersonWithStatus;
    searching: boolean;
}

const searchDefaults: PermitTeamSearch = {
    errorMessage: undefined,
    query: undefined,
    person: undefined,
    searching: false,
};

const teamDefaults: PermitTeam = {
    canAddMember: true,
    fireWatchers: undefined,
    fireWatchersNotNeeded: false,
    hotWorkers: undefined,
    permitIssuer: undefined,
    postFireWatchers: undefined,
    observers: undefined,
};

const newPersonFormDefaults = {
    creating: false,
    model: undefined,
    dirty: false,
    status: "",
    errors: {},
};

@Injectable()
@State<PermitTeamStateModel>({
    name: "permitTeam",
    defaults: {
        recentTeamMembers: undefined,
        recentTeamMembersFullyLoaded: undefined,
        stageRole: undefined,
        search: searchDefaults,
        stagedPersons: undefined,
        team: teamDefaults,
        newPersonForm: newPersonFormDefaults,
        newPersonFormResponse: undefined,
    },
})
export class PermitTeamState {
    constructor(private permitTeamService: PermitTeamService) {}

    @Action(PermitTeamActions.AddStagedPersonsToPermit)
    public addStagedPersonsToPermit(
        ctx: StateContext<PermitTeamStateModel>,
        action: PermitTeamActions.AddStagedPersonsToPermit,
    ): Observable<AddStagedPersonsToPermitResponse> {
        const currentState = ctx.getState();
        const command: AddStagedPersonsToPermitCommand = {
            permitId: action.permitId,
            personsCertificateId: currentState.stagedPersons.map((_) => _.certificateId),
            role: action.role,
        };

        return this.permitTeamService.addStagedPersonsToPermit(command).pipe(tap(() => {
            ctx.dispatch(new PermitTeamActions.GetTeam(action.permitId));

            ctx.setState(produce((draft) => {
                draft.stagedPersons = undefined;
                draft.stageRole = undefined;
            }));
        }));
    }

    @Action(PermitTeamActions.ClearCreatePersonFormData)
    public clearCreatePersonFormData(ctx: StateContext<PermitTeamStateModel>): void {
        ctx.setState(produce((draft) => {
            draft.newPersonForm = newPersonFormDefaults;
        }));
    }

    @Action(PermitTeamActions.CreateCertificateAndStagePerson)
    public createCertificateAndStagePerson(
        ctx: StateContext<PermitTeamStateModel>,
        action: PermitTeamActions.CreateCertificateAndStagePerson,
    ): Observable<CreateCertificateAndPersonResponse> {
        const command = <CreateCertificateAndPersonCommand> <unknown> {
            ...ctx.getState().newPersonForm.model,
            selected: false,
        };
        command.permitId = action.permitId;
        command.role = action.role;

        ctx.setState(produce((draft) => {
            draft.newPersonForm.creating = true;
        }));

        return this.permitTeamService.createCertificateAndPerson(command).pipe(
            tap((response: CreateCertificateAndPersonResponse) => {
                if (response.success) {
                    ctx.dispatch(
                        new PermitTeamActions.StagePerson({
                            birthDate: command.birthDate,
                            certificateId: response.certificateId,
                            certificateIdentifier: command.certificateIdentifier,
                            concept: command.conceptType,
                            email: undefined,
                            expirationDate: command.expirationDate,
                            hasValidCertificate: true,
                            firstName: command.firstName,
                            lastName: command.lastName,
                            mobile: command.mobile,
                            personId: response.personId,
                            personalNumber: undefined,
                            signature: undefined,
                            endSignature: undefined,
                            hasAcceptedUserAgreement: false,
                        }),
                    );
                }

                ctx.setState(produce((draft) => {
                    draft.newPersonForm = newPersonFormDefaults;
                    draft.newPersonFormResponse = response;
                }));
            }),
        );
    }

    @Action(PermitTeamActions.FindPerson)
    public findPerson(
        ctx: StateContext<PermitTeamStateModel>,
        action: PermitTeamActions.FindPerson,
    ): Observable<FindCertifiedPersonResult> {
        ctx.setState(produce(ctx.getState(), (draft) => {
            draft.search.person = undefined;
            draft.search.errorMessage = undefined;
            draft.search.searching = true;
            draft.search.query = action.query;
        }));

        return this.permitTeamService.findPerson(action.query).pipe(tap((result: FindCertifiedPersonResult) => {
            if (!result || result.status == CertificateStatus.CertificateNotFound) {
                ctx.setState(produce(ctx.getState(), (draft) => {
                    draft.search.errorMessage = "notfound";
                    draft.search.searching = false;
                }));

                return;
            }

            if (result.status == CertificateStatus.ValidCertificate) {
                if (action.query.role == PersonRole.PermitIssuer && result.concept !== ConceptType.HetaArbeten) {
                    ctx.setState(produce(ctx.getState(), (draft) => {
                        draft.search.errorMessage = "wrongconcept";
                        draft.search.searching = false;
                    }));

                    return;
                }

                const member = this.convertTeamMemberToMemberWithStatus(ctx, {
                    birthDate: result.user.birthDate,
                    certificateId: result.id,
                    certificateIdentifier: result.certificateIdentifier,
                    concept: result.concept,
                    email: result.user.email,
                    expirationDate: result.expirationDate,
                    hasValidCertificate: true,
                    firstName: result.user.firstName,
                    lastName: result.user.lastName,
                    mobile: result.user.mobile,
                    personId: 0,
                    personalNumber: result.user.personalNumber,
                    signature: undefined,
                    endSignature: undefined,
                    hasAcceptedUserAgreement: false,
                });

                ctx.setState(produce(ctx.getState(), (draft) => {
                    draft.search.person = member;
                    draft.search.searching = false;
                }));
            } else {
                ctx.setState(produce(ctx.getState(), (draft) => {
                    draft.search.errorMessage = "notvalid";
                    draft.search.searching = false;
                }));
            }
        }));
    }

    @Action(PermitTeamActions.GetRecentTeamMembers)
    public getRecentTeamMembers(
        ctx: StateContext<PermitTeamStateModel>,
        action: PermitTeamActions.GetRecentTeamMembers,
    ): Observable<unknown[]> {

        if (action.restart) {
            ctx.patchState({
                recentTeamMembers: [],
                recentTeamMembersFullyLoaded: false,
            });
        }

        if (ctx.getState().recentTeamMembersFullyLoaded) { // Warning: use of cached list circumvents server logic
            return of(ctx.getState().recentTeamMembers);
        }

        const skip = ctx.getState()?.recentTeamMembers?.length ?? 0;

        return this.permitTeamService.getRecentTeamMembers(action.permitId, skip, action.take + 1).pipe(tap((teamMembers: PermitPerson[]) => {
            const teamMembersWithStatus: PermitPersonWithStatus[] = [];

            const recentTeamMembersFullyLoaded = teamMembers.length <= action.take;

            teamMembers.slice(0, action.take).forEach((member) => {
                const teamMemberWithStatus: PermitPersonWithStatus = this.convertTeamMemberToMemberWithStatus(
                    ctx,
                    member,
                );
                teamMembersWithStatus.push(teamMemberWithStatus);
            });

            ctx.setState(produce(ctx.getState(), (draft) => {
                draft.recentTeamMembers = [ ...draft.recentTeamMembers ?? [], ...teamMembersWithStatus];
                draft.recentTeamMembersFullyLoaded = recentTeamMembersFullyLoaded;
            }));
        }));
    }

    @Action(PermitTeamActions.GetTeam)
    public getTeam(ctx: StateContext<PermitTeamStateModel>, action: PermitTeamActions.GetTeam): Observable<PermitTeam> {
        return this.permitTeamService.getTeam(action.permitId).pipe(tap((result: PermitTeam) => {
            ctx.setState(produce(ctx.getState(), (draft) => {
                draft.team = result;
            }));
        }));
    }

    @Action(PermitTeamActions.RemovePerson)
    public removePerson(
        ctx: StateContext<PermitTeamStateModel>,
        action: PermitTeamActions.RemovePerson,
    ): Observable<BaseValidationResult> {
        return this.permitTeamService.removePerson(action.command).pipe(tap(() => {
            ctx.dispatch(new PermitTeamActions.GetTeam(action.command.permitId));
        }));
    }

    @Action(PermitTeamActions.ResetFind)
    public resetFind(ctx: StateContext<PermitTeamStateModel>) {
        return ctx.patchState({ search: searchDefaults });
    }

    @Action(PermitTeamActions.SetAddPersonView)
    public setAddPersonView(ctx: StateContext<PermitTeamStateModel>, action: PermitTeamActions.SetAddPersonView) {
        return ctx.patchState({ stageRole: action.role });
    }

    @Action(PermitTeamActions.SetFireWatchersNotNeeded)
    public setFireWatchersNotNeeded(
        ctx: StateContext<PermitTeamStateModel>,
        action: PermitTeamActions.SetFireWatchersNotNeeded,
    ) {
        return this.permitTeamService.setFireWatchersNotNeeded(action.command).pipe(tap(() => {
            ctx.dispatch(new PermitTeamActions.GetTeam(action.command.permitId));
        }));
    }

    @Action(PermitTeamActions.SignPermitWithSignature)
    public signPermitByPerson(
        ctx: StateContext<PermitTeamStateModel>,
        action: PermitTeamActions.SignPermitWithSignature,
    ) {
        return this.permitTeamService.signPermitWithSignature(action.command).pipe(tap(() => {
            ctx.dispatch(new PermitTeamActions.GetTeam(action.command.permitId));
        }));
    }

    @Action(PermitTeamActions.StagePerson)
    public stagePerson(ctx: StateContext<PermitTeamStateModel>, action: PermitTeamActions.StagePerson) {
        return ctx.setState(produce(ctx.getState(), (draft) => {
            if (!draft.stagedPersons) {
                draft.stagedPersons = [action.person];
            } else {
                if (!draft.stagedPersons.find((_) => _.certificateId == action.person.certificateId)) {
                    draft.stagedPersons.push(action.person);
                }
            }
        }));
    }

    @Action(PermitTeamActions.UnstageAll)
    public unstageAll(ctx: StateContext<PermitTeamStateModel>) {
        return ctx.setState(produce(ctx.getState(), (draft) => {
            draft.stagedPersons = undefined;
        }));
    }

    @Action(PermitTeamActions.UnstagePerson)
    public unstagePerson(ctx: StateContext<PermitTeamStateModel>, action: PermitTeamActions.UnstagePerson) {
        return ctx.setState(produce(ctx.getState(), (draft) => {
            if (draft.stagedPersons) {
                draft.stagedPersons = draft.stagedPersons.filter((_) => _.certificateId != action.certificateId);
            }
        }));
    }

    private convertTeamMemberToMemberWithStatus(
        ctx: StateContext<PermitTeamStateModel>,
        member: PermitPerson,
    ): PermitPersonWithStatus {
        const currentState = ctx.getState();
        const currentRole = currentState.stageRole;
        const currentTeam = currentState.team;

        const now: Date = new Date();
        const soon: Date = new Date();
        soon.setMonth(soon.getMonth() + 3);

        const teamMemberWithStatus: PermitPersonWithStatus = member as PermitPersonWithStatus;

        if (new Date(teamMemberWithStatus.expirationDate) < now) {
            teamMemberWithStatus.certificateExpired = true;
        }
        if (new Date(teamMemberWithStatus.expirationDate) < soon) {
            teamMemberWithStatus.certificateExpireSoon = true;
        }

        teamMemberWithStatus.canBeAdded = canBeAdded();

        function canBeAdded(): boolean {
            if (currentRole === PersonRole.PermitIssuer) {
                if (currentTeam.hotWorkers.find((_) => _.certificateId == teamMemberWithStatus.certificateId)) {
                    return false;
                }

                if (currentTeam.permitIssuer.certificateId == teamMemberWithStatus.certificateId) {
                    return false;
                }
            }

            if (currentRole === PersonRole.HotWorker) {
                if (currentTeam.permitIssuer.certificateId == teamMemberWithStatus.certificateId) {
                    return false;
                }

                if (currentTeam.fireWatchers.find((_) => _.certificateId == teamMemberWithStatus.certificateId)) {
                    return false;
                }

                if (currentTeam.hotWorkers.find((_) => _.certificateId == teamMemberWithStatus.certificateId)) {
                    return false;
                }
            }

            if (currentRole === PersonRole.FireWatcher) {
                if (currentTeam.hotWorkers.find((_) => _.certificateId == teamMemberWithStatus.certificateId)) {
                    return false;
                }

                if (currentTeam.fireWatchers.find((_) => _.certificateId == teamMemberWithStatus.certificateId)) {
                    return false;
                }
            }

            if (currentRole === PersonRole.PostFireWatcher) {
                if (currentTeam.postFireWatchers.find((_) => _.certificateId == teamMemberWithStatus.certificateId)) {
                    return false;
                }
            }

            return true;
        }

        return teamMemberWithStatus;
    }
}
