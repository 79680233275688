<div class="start" *transloco="let t; read:'permit.workplace.start'">
    <pmt-workplace-recent></pmt-workplace-recent>
    <div *ngIf="(canCreateBaseOrDemoPermits$ | async) === true">
        <hr class="start__divider">
    </div>

    <button *ngIf="hasPremiumWorkplaceContainers$ | async" class="start__button start__button--find-by-company" (click)="premium()">
        {{ t("buttonFindByCompany")}}
    </button>

    <button class="start__button start__button--create-new" *ngIf="(canCreateBaseOrDemoPermits$ | async) === true" (click)="basePermit()">
        {{ t("buttonCreateNew") }}
    </button>

    <div class="start__noUserRights" *ngIf="(canCreateBaseOrDemoPermits$ | async) === false && (hasPremiumWorkplaceContainers$ | async) === false">
        {{ t("noUserRights") }}
    </div>

    <div class="start__actions">
        <button class="start__actions__back" type="button" (click)="goBack()">{{ t("back") }}</button>
    </div>
</div>
