import { PersonRole } from "@ha/data/basic";
import { Selector } from "@ngxs/store";
import { PermitCreateSelectors } from "../../permit-create/states/permit-create.selectors";

import { SubscriptionsSelectors } from "../../subscriptions/states/subscriptions.selectors";
import { PermitPerson } from "../models/permit-person.model";
import { PermitToSign } from "../models/permit-to-sign.model";
import { PermitUserState, PermitUserStateModel } from "./permit-user.state";

export class PermitUserSelectors {
    @Selector([PermitUserState])
    public static currentPerson(state: PermitUserStateModel): PermitPerson | undefined {
        return state?.currentPerson;
    }

    @Selector([PermitUserState])
    public static isHotWorker(state: PermitUserStateModel): boolean | undefined {
        if (state?.isHotWorker !== undefined) {
            return state.isHotWorker;
        }

        return undefined;
    }

    @Selector([PermitUserState])
    public static canCreateBaseOrDemoPermits(state: PermitUserStateModel): boolean {
        if (state?.isHotWorker === true || state?.canCreateDemoPermits) {
            return true;
        }

        return false;
    }

    @Selector([PermitUserState])
    public static showCreateDemoButton(state: PermitUserStateModel): boolean {
        return state?.canCreateDemoPermits;
    }

    @Selector([PermitUserState])
    public static haveAccessToPermit(state: PermitUserStateModel): boolean | undefined {
        if (state?.isHotWorker !== undefined
                && state?.isExternal !== undefined
                && state?.canCreateDemoPermits !== undefined
                && state?.isAdministrator !== undefined) {
            return state.isHotWorker || state.isExternal || state.canCreateDemoPermits || state.isAdministrator;
        }

        return undefined;
    }

    @Selector([PermitUserState, SubscriptionsSelectors.hasActiveSubscription])
    public static isHotworkerOrPremiumIssuer(state: PermitUserStateModel, hasActiveSubscription: boolean): boolean | undefined {
        if (state?.isHotWorker === true) {
            return true;
        }

        return state?.isExternal === true && hasActiveSubscription;
    }

    @Selector([PermitUserState])
    public static permitsToSign(state: PermitUserStateModel): PermitToSign[] | undefined {
        return state?.permitsToSign?.filter(_ => _.role !== PersonRole.PendingIssuer);
    }

    @Selector([PermitUserState])
    public static permitsToClaim(state: PermitUserStateModel): PermitToSign[] | undefined {
        return state?.permitsToSign?.filter(_ => _.role === PersonRole.PendingIssuer);
    }

    @Selector([PermitUserState])
    public static allPermitNotifications(state: PermitUserStateModel): PermitToSign[] | undefined {
        return state?.permitsToSign;
    }
}
