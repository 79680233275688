import { Injectable } from "@angular/core";
import { BaseValidationResult } from "@ha/ui/forms";
import { Navigate } from "@ngxs/router-plugin";
import { Action, State, StateContext, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { PermitPaths } from "../../permit-paths";
import { PermitListItem } from "../models/permit-list-item.model";
import { PreparedPermitListItem } from "../models/prepared-permit-list-item.model";
import { PermitListService } from "../services/permit-list.service";
import { PermitListActions } from "./permit-list.actions";

export interface PermitListStateModel {
    permits: PermitListItem[] | undefined;
    preparedPermits: PreparedPermitListItem[] | undefined;
    ongoingPermits: PermitListItem[] | undefined;
    plannedPermits: PermitListItem[] | undefined;
    completedPermits: PermitListItem[] | undefined;
}

@Injectable()
@State<PermitListStateModel>({
    name: "permitList",
})
export class PermitListState {
    constructor(private store: Store, private permitListService: PermitListService) {}

    @Action(PermitListActions.GetAllCompleted)
    public getAllCompleted(ctx: StateContext<PermitListStateModel>): Observable<PermitListItem[]> {
        return this.permitListService.getAllCompleted().pipe(tap((data) => {
            ctx.patchState({
                completedPermits: data,
            });
        }));
    }

    @Action(PermitListActions.GetAllOngoing)
    public getAllOngoing(ctx: StateContext<PermitListStateModel>): Observable<PermitListItem[]> {
        return this.permitListService.getAllOngoing().pipe(tap((data) => {
            ctx.patchState({
                ongoingPermits: data,
            });
        }));
    }

    @Action(PermitListActions.GetAllPlanned)
    public getAllPlanned(ctx: StateContext<PermitListStateModel>): Observable<PermitListItem[]> {
        return this.permitListService.getAllPlanned().pipe(tap((data) => {
            ctx.patchState({
                plannedPermits: data,
            });
        }));
    }

    @Action(PermitListActions.GetAllPrepared)
    public getAllPrepared(ctx: StateContext<PermitListStateModel>): Observable<PreparedPermitListItem[]> {
        return this.permitListService.getAllPrepared().pipe(tap((data) => {
            ctx.patchState({
                preparedPermits: data,
            });
        }));
    }

    @Action(PermitListActions.ClaimPreparedPermit)
    public claimPreparedPermit(ctx: StateContext<PermitListStateModel>, action: PermitListActions.ClaimPreparedPermit): Observable<BaseValidationResult> {
        return this.permitListService.claimPreparedPermit(action.permitId).pipe(tap((result) => {
            if (result.success) {
                this.store.dispatch(new Navigate([PermitPaths.Root, action.permitId]));
            }
        }));
    }

    @Action(PermitListActions.RemovePlannedPermit)
    public removePlannedPermit(ctx: StateContext<PermitListStateModel>, action: PermitListActions.RemovePlannedPermit): Observable<BaseValidationResult> {
        return this.permitListService.removePlannedPermit(action.permitId).pipe(tap((result) => {
            if (result.success) {
                // Success
            }
        }));
    }
}
