<div class="premium" *transloco="let t; read:'permit.workplace.premium'">
    <ha-select *ngIf="(selectedNode$ | async) === undefined" class="premium__subscriptions"
            [label]="t('company')" [options]="subscriptions$ | async" [(ngModel)]="selectedSubscriptionId"></ha-select>

    <pmt-workplace-search *ngIf="selectedSubscriptionId > 0"></pmt-workplace-search>

    <div class="premium__workplaces" *ngIf="(searchResult$ | async) === undefined">
        <pmt-workplace-premium-item class="premium__workplaces__item" *ngFor="let structureNode of filterStructureNodes(structureNodes$ | async)"
                                    [structureNode]="structureNode">
        </pmt-workplace-premium-item>
    </div>

    <div class="premium__actions">
        <button *ngIf="(selectedNode$ | async) === undefined; else selectedNode" class="premium__actions__cancel" (click)="cancel()">
            {{ t("cancel") }}
        </button>

        <ng-template #selectedNode>
            <button class="premium__actions__back" (click)="back()">{{ t("back") }}</button>
            <button class="premium__actions__continue" type="button" (click)="continue()"
                    [class.premium__actions__continue--disabled]="(selectedWorkplace$ | async) === undefined">{{ t("next") }}</button>
        </ng-template>
    </div>
</div>
