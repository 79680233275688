import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Workplace } from "@ha/data/basic";
import { SelectionListItem } from "@ha/ui/forms";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { IssuerStructureNode } from "../../../models/issuer-structure-node.model";
import { PermitWorkPlaceViewEnum } from "../../../models/permit-work-place-view.enum";
import { PermitWorkplaceActions } from "../../../states/permit-workplace.actions";
import { PermitWorkplaceSelectors } from "../../../states/permit-workplace.selectors";

@Component({
    selector: "pmt-workplace-premium",
    templateUrl: "permit-workplace-premium.component.html",
    styleUrls: ["permit-workplace-premium.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PermitWorkplacePremiumComponent {
    @Select(PermitWorkplaceSelectors.subscriptions)
    public subscriptions$: Observable<SelectionListItem[]>;

    @Select(PermitWorkplaceSelectors.structureNodes)
    public structureNodes$: Observable<IssuerStructureNode[]>;

    @Select(PermitWorkplaceSelectors.selectedNode)
    public selectedNode$: Observable<IssuerStructureNode>;

    @Select(PermitWorkplaceSelectors.selectedWorkplace)
    public selectedWorkplace$!: Observable<Workplace>;

    @Select(PermitWorkplaceSelectors.searchResult)
    public searchResult$!: Observable<(IssuerStructureNode | Workplace)[]>;

    private selectedSubscriptionIdInternal: number;

    public get selectedSubscriptionId() {
        return this.selectedSubscriptionIdInternal ?? this.store.selectSnapshot(PermitWorkplaceSelectors.structure)[0].subscriptionId;
    }

    public set selectedSubscriptionId(value: number) {
        this.selectedSubscriptionIdInternal = value;
        this.store.dispatch(new PermitWorkplaceActions.SelectSubscription(value));
    }

    constructor(private store: Store) { }

    public filterStructureNodes(containers: IssuerStructureNode[]) {
        return this.selectedSubscriptionId
            ? containers?.filter(_ => _.subscriptionId === this.selectedSubscriptionId && _.isSubscriptionActive)
            : undefined;
    }

    public cancel() {
        this.store.dispatch(new PermitWorkplaceActions.ChangeView(PermitWorkPlaceViewEnum.Start));
    }

    public continue() {
        this.store.dispatch(new PermitWorkplaceActions.SetPremiumWorkplace());
    }

    public back() {
        const parent = this.store.selectSnapshot(PermitWorkplaceSelectors.parentNode);

        // Is root node?
        if (!parent.parentId) {
            this.store.dispatch(new PermitWorkplaceActions.ToggleSelectedNode(undefined));
        } else {
            this.store.dispatch(new PermitWorkplaceActions.ToggleSelectedNode(parent));
        }
    }
}
