<div class="root">
    <div class="root__list" *ngIf="permits">
        <div *transloco="let t; read: 'permit.publicStatus'" class="root__legends">
            <ng-container *ngFor="let key of statusKeys()">
                <pmt-permit-public-status *ngIf="statusExists(PublicPermitStatus[key])" [status]="PublicPermitStatus[key]"></pmt-permit-public-status>
            </ng-container>
        </div>

        <div *transloco="let t; read: 'permit'">
            <div class="root__noPermits" *ngIf="permits?.length <= 0">
                {{ t("noPermits") }}
            </div>
        </div>
        <pmt-permit-list-item [item]="permit" *ngFor="let permit of permits"></pmt-permit-list-item>
    </div>
</div>
