<div class="control" [class.control--empty]="hasNeitherValueNorFocusNorPrefix" [class.control--required]="isRequired" [class.control--invalid]="invalidAndDirty"
     [class.control--disabled]="isDisabled">

    <ng-container *ngIf="!isDateType">
        <div class="control__box">
            <div *ngIf="prefix" class="control__box__prefix" [innerHTML]="prefix"></div>
            <input [autofocus]="autofocus" #inputElement [attr.name]="formControlName ?? name" class="control__box__input"
                   [class.control__box__input--uppercase]="uppercaseOnly" [type]="type" (focus)="onFocus(); onInputFocus()"
                   (blur)="onBlur($event); onInputBlur()" [(ngModel)]="value" [maxlength]="maxlength" [attr.autocomplete]="autocomplete"
                   [attr.disabled]="isDisabled && !readOnlyOnDisabled ? '' : null" [attr.readOnly]="isDisabled && readOnlyOnDisabled ? '' : null" [min]="min"
                   [max]="max">
        </div>
        <label class="control__label">{{ label }}</label>
    </ng-container>

    <ng-container *ngIf="isDateType">
        <div class="control__box">
            <div *ngIf="prefix" class="control__box__prefix" [innerHTML]="prefix"></div>
            <input [autofocus]="autofocus" #inputElement [attr.name]="formControlName ?? name" class="control__box__input" (click)="picker.open()"
                   (focus)="onFocus(); onInputFocus()" (blur)="onBlur($event); onInputBlur()" [(ngModel)]="value"
                   [attr.disabled]="isDisabled && !readOnlyOnDisabled ? '' : null" [attr.readOnly]="isDisabled && readOnlyOnDisabled ? '' : null" [min]="min"
                   [max]="max" matInput [matDatepicker]="picker" (dateChange)="emitDate($event)">
        </div>
        <label class="control__label">{{ label }}</label>

        <mat-datepicker #picker [touchUi]="isNotDesktop" startView="multi-year"></mat-datepicker>
    </ng-container>
</div>
