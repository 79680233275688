<div class="team" *transloco="let t; read:'permit.team'">
    <ng-container *ngIf="permitTeam$ | async as team">
        <div class="team__role">
            <div class="team__role__heading" [innerHTML]="t('roles.permitissuer')"></div>
            <div class="team__role__participants">{{ team.permitIssuer?.firstName }} {{ team.permitIssuer?.lastName }}</div>
            <pmt-permit-change-issuer [permitId]="permitId"></pmt-permit-change-issuer>
        </div>

        <div class="team__role" *ngIf="team.observers?.length > 0">
            <h3 class="team__role__heading" [innerHTML]="t('roles.observer')"></h3>
            <ng-container [ngTemplateOutlet]="personList" [ngTemplateOutletContext]="{teamMembers: team.observers, personRole: PersonRole.Observer}">
            </ng-container>
        </div>

        <div class="team__role">
            <h3 class="team__role__heading" [innerHTML]="t('roles.hotworker')"></h3>
            <ng-container [ngTemplateOutlet]="personList" [ngTemplateOutletContext]="{teamMembers: team.hotWorkers, personRole: PersonRole.HotWorker}">
            </ng-container>
        </div>

        <div class="team__role">
            <h3 class="team__role__heading" [innerHTML]="t('roles.firewatcher')"></h3>
            <div class="team__role__participants">
                <div *ngIf="team.fireWatchersNotNeeded && (!team.fireWatchers || team.fireWatchers.length <= 0)" class="team__role__new-participant-list__item"
                     [innerHTML]="t('noFireWatcherNeeded')"></div>
                <div class="team__role__new-participant-list__add" *ngIf="(currentUserIsIssuer$ | async)" (click)="add(PersonRole.FireWatcher)"></div>
                <ng-container *ngIf="!team.fireWatchersNotNeeded" [ngTemplateOutlet]="personList"
                              [ngTemplateOutletContext]="{teamMembers: team.fireWatchers, personRole: PersonRole.FireWatcher}">
                </ng-container>
            </div>
        </div>

        <div class="team__role">
            <h3 class="team__role__heading" [innerHTML]="t('roles.postfirewatcher')"></h3>
            <ng-container [ngTemplateOutlet]="personList"
                          [ngTemplateOutletContext]="{teamMembers: team.postFireWatchers, personRole: PersonRole.PostFireWatcher}">
            </ng-container>
        </div>

        <ng-template #personList let-teamMembers="teamMembers" let-personRole="personRole">
            <div class="team__role__participants">
                <ng-container *ngFor="let teamMember of filterSigned(teamMembers); let last = last">
                    {{teamMember.firstName}} {{teamMember.lastName}}{{ !last ? ", " : ""}}
                </ng-container>
            </div>

            <ng-container *ngIf="personRole !== PersonRole.Observer && team.canAddMember && filterNotSigned(teamMembers) as notSignedTeamMembers">
                <ng-container *transloco="let t; read:'permit.details.team'">
                    <div *ngIf="notSignedTeamMembers?.length === 0 && (currentUserIsIssuer$ | async)" class="new-participant-anchor" (click)="add(personRole)"
                         [innerHTML]="t('noUnsigned.' + personRoleToString(personRole))">
                    </div>
                </ng-container>

                <div *ngIf="notSignedTeamMembers?.length > 0" class="new-participant-list">
                    <div *ngFor="let teamMember of notSignedTeamMembers" class="new-participant-list__item" (click)="removeOrSign(teamMember, personRole)">
                        <div class="new-participant-list__item__text">{{teamMember.firstName}} {{teamMember.lastName}}</div>
                        <div class="new-participant-list__item__delete"></div>
                    </div>
                    <div class="new-participant-list__add-button" *ngIf="(currentUserIsIssuer$ | async)" (click)="add(personRole)"></div>
                </div>
            </ng-container>
        </ng-template>
    </ng-container>
</div>
