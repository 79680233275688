import { PersonRole } from "@ha/data/basic";
import { Selector } from "@ngxs/store";

import { PermitCreateState, PermitCreateStateModel } from "../../permit-create/states/permit-create.state";
import { PermitPerson, PermitPersonWithStatus } from "../../shared/models/permit-person.model";
import { CreateCertificateAndPersonResponse } from "../models/create-certificate-person.model";
import { PermitTeam } from "../models/permit-team.model";
import { PermitTeamSearch, PermitTeamState, PermitTeamStateModel } from "./permit-team.state";

export class PermitTeamSelectors {
    // @Selector([PermitTeamState])
    // public static allowExternalCertificates(state: PermitTeamStateModel): boolean {
    //     if (!state.isPremium) {
    //         return true;
    //     }

    //     return state?.allowExternalCertificates;
    // }

    // @Selector([PermitTeamState])
    // public static isPremium(state: PermitTeamStateModel): boolean {
    //     return state?.isPremium;
    // }

    @Selector([PermitTeamState])
    public static addPersonRole(state: PermitTeamStateModel): PersonRole {
        return state?.stageRole;
    }

    @Selector([PermitTeamState])
    public static creatingPerson(state: PermitTeamStateModel): boolean {
        return state?.newPersonForm.creating;
    }

    @Selector([PermitTeamState])
    public static fireWatcherNotNeeded(state: PermitTeamStateModel): boolean {
        return state?.team.fireWatchersNotNeeded;
    }

    @Selector([PermitTeamState])
    public static newPersonFormResponse(state: PermitTeamStateModel): CreateCertificateAndPersonResponse {
        return state?.newPersonFormResponse;
    }

    @Selector([PermitTeamState])
    public static permitIssuer(state: PermitTeamStateModel): PermitPerson {
        return state?.team.permitIssuer;
    }

    @Selector([PermitTeamState])
    public static recentTeamMembers(state: PermitTeamStateModel): PermitPersonWithStatus[] {
        return state?.recentTeamMembers;
    }

    @Selector([PermitTeamState])
    public static recentTeamMembersFullyLoaded(state: PermitTeamStateModel): boolean {
        return state?.recentTeamMembersFullyLoaded;
    }

    @Selector([PermitTeamState])
    public static searchResult(state: PermitTeamStateModel): PermitTeamSearch {
        return state?.search;
    }

    @Selector([PermitTeamState])
    public static stagedPersons(state: PermitTeamStateModel): PermitPerson[] {
        return state?.stagedPersons;
    }

    @Selector([PermitTeamState])
    public static team(state: PermitTeamStateModel): PermitTeam {
        return state?.team;
    }

    @Selector([PermitTeamState, PermitTeamSelectors.teamValid, PermitCreateState])
    public static teamSigned(
        state: PermitTeamStateModel,
        teamValid: boolean,
        permitCreateState: PermitCreateStateModel,
    ): boolean {
        if (!teamValid) {
            return false;
        }

        // If permit is not flammable only the issuer have to sign.
        if (!permitCreateState.permit?.isFlammableHotWork && state.team?.permitIssuer?.signature) {
            return true;
        }

        // If permit is demo only the issuer have to sign.
        if (permitCreateState.permit?.isDemo && state.team?.permitIssuer?.signature) {
            return true;
        }

        const team = state.team;
        if (!team.permitIssuer.signature) {
            return false;
        }

        if (team.hotWorkers.some((_) => !_.signature)) {
            return false;
        }

        if (!team.fireWatchersNotNeeded && team.fireWatchers.some((_) => !_.signature)) {
            return false;
        }

        if (team.postFireWatchers.some((_) => !_.signature)) {
            return false;
        }

        return true;
    }

    @Selector([PermitTeamState, PermitCreateState])
    public static teamValid(state: PermitTeamStateModel, permitCreateState: PermitCreateStateModel): boolean {
        const team = state?.team;

        // If permit is not flammable only the issuer is needed on team.
        if (!permitCreateState?.permit?.isFlammableHotWork && team?.permitIssuer) {
            return true;
        }

        if (!team.permitIssuer) {
            return false;
        }

        if (!team.hotWorkers || team.hotWorkers.length == 0) {
            return false;
        }

        if (!team.fireWatchersNotNeeded && (team.fireWatchers && team.fireWatchers.length == 0)) {
            return false;
        }

        if (!team.postFireWatchers || team.postFireWatchers.length == 0) {
            return false;
        }

        return true;
    }

    @Selector([PermitTeamState])
    public static postFireWatcherHasSigned(state: PermitTeamStateModel): boolean {
        return state?.team?.postFireWatchers?.some(_ => _.endSignature !== null);
    }
}
