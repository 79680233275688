import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { PersonRole } from "@ha/data/basic";
import { HaModalService } from "@ha/ui/common";
import { PwaState } from "@ha/util/pwa";
import { toCamelCase } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import {
    AddPersonModalComponent,
    AddPersonModalComponentData,
} from "../../../permit-team/components/add-person-modal/add-person-modal.component";
import { PermitTeam } from "../../../permit-team/models/permit-team.model";
import { PermitTeamActions } from "../../../permit-team/states/permit-team.action";
import { PermitTeamSelectors } from "../../../permit-team/states/permit-team.selectors";
import { PermitPerson } from "../../../shared/models/permit-person.model";
import { PermitDetailsSelectors } from "../../states/permit-details.selectors";
import {
    PermitDetailsTeamRemoveOrSignModalComponent,
    PermitDetailsTeamRemoveOrSignModalComponentData,
} from "./permit-details-team-remove-or-sign/permit-details-team-remove-or-sign.component";

@UntilDestroy()
@Component({
    selector: "pmt-details-team",
    templateUrl: "permit-details-team.component.html",
    styleUrls: ["permit-details-team.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PermitDetailsTeamComponent implements OnInit {
    @Input() public permitId: number;

    @Select(PermitDetailsSelectors.currentUserIsIssuer)
    public currentUserIsIssuer$: Observable<boolean>;

    @Select(PermitTeamSelectors.team)
    public permitTeam$: Observable<PermitTeam>;

    @Select(PwaState.isMobile)
    public isMobile$: Observable<boolean>;

    public canProceed = false;
    public PersonRole = PersonRole;

    constructor(private modalService: HaModalService, private store: Store) {
    }

    public ngOnInit(): void {
        this.store.dispatch(new PermitTeamActions.GetTeam(this.permitId));

        this.store.select(PermitTeamSelectors.teamValid)
            .pipe(untilDestroyed(this))
            .subscribe((valid) => {
                this.canProceed = valid;
            });
    }

    public filterSigned(permitPersons: PermitPerson[]) {
        return permitPersons?.filter(_ => _.signature);
    }

    public filterNotSigned(permitPersons: PermitPerson[]) {
        return permitPersons?.filter(_ => !_.signature);
    }

    public personRoleToString(value: PersonRole): string {
        return toCamelCase(PersonRole[value]);
    }

    public add(role: PersonRole): void {
        this.store.dispatch(new PermitTeamActions.SetAddPersonView(role));
        this.modalService.open(AddPersonModalComponent, true, <AddPersonModalComponentData>{
            permitId: this.permitId,
            role: role,
            allowExternalCertificates: this.store.selectSnapshot(PermitDetailsSelectors.allowExternalCertificates),
            detailsMode: true,
        });
    }

    public removeOrSign(permitPerson: PermitPerson, role: PersonRole) {
        this.modalService.open(PermitDetailsTeamRemoveOrSignModalComponent, false, <PermitDetailsTeamRemoveOrSignModalComponentData>{
            permitId: this.permitId,
            person: permitPerson,
            role: role,
        });
    }
}
