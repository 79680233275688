<ng-container *ngIf="(photoRequirement$ | async) !== undefined">
    <div class="root" *transloco="let t; read:'permit.permitPhoto'">
        <div *ngIf="(photoRequirement$ | async) === PhotoRequirement.Prohibited" class="root__photo-prohibited">{{ t("photoProhibited") }}</div>
        <ng-container *ngIf="photos$ | async as photos">
            <div class="root__photo-list">
                <div *ngFor="let photo of photos" class="root__photo-list__item">
                    <img class="root__photo-list__item__image" [src]="photo.url" (click)="openModal(photo)">
                </div>
            </div>

            <div *ngIf="(photoRequirement$ | async) !== PhotoRequirement.Prohibited && photos.length <= 0" class="root__no-photos">
                {{ t("noPhotos") }}
            </div>
        </ng-container>

        <div *ngIf="(photoRequirement$ | async) !== PhotoRequirement.Prohibited" class="root__photo-warning">{{ t("photoWarning") }}</div>

        <input #fileUpload class="root__file-input" type="file" multiple accept="image/*" capture="environment" (change)="processSelectedImages(fileUpload)">

        <button *ngIf="(currentStep$ | async)?.step !== PermitStep.ended && canAddPhoto((photoRequirement$ | async)) && ((currentUserIsObserver$ | async) === false)"
                (click)="fileUpload.click()" class="root__upload-button">
            <div class="root__upload-button__text">{{t("addPhoto") }}</div>
        </button>
    </div>
</ng-container>
