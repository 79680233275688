import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { PermitPerson } from "../../../shared/models/permit-person.model";
import { PermitTeamActions } from "../../states/permit-team.action";
import { PermitTeamSelectors } from "../../states/permit-team.selectors";

export interface FireWatcherNotNeededModalComponentData {
    permitId: number;
}

@Component({
    selector: "pmt-firewatcher-not-needed",
    templateUrl: "firewatcher-not-needed.component.html",
    styleUrls: ["firewatcher-not-needed.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class FireWatcherNotNeededComponent implements OnInit {
    @Output()
    public requiredChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    public permitId: number;

    public form: UntypedFormGroup;

    @Select(PermitTeamSelectors.permitIssuer)
    public permitIssuer$: Observable<PermitPerson>;

    constructor(private fb: UntypedFormBuilder, private store: Store) {
    }

    public ngOnInit() {
        this.createForm();
    }

    public cancel() {
        this.requiredChange.emit(false);
    }

    public save() {
        if (this.form.valid) {
            this.store.dispatch(new PermitTeamActions.SetFireWatchersNotNeeded({ permitId: this.permitId, fireWatchersNotNeeded: true }));
            this.requiredChange.emit(true);
        }
    }

    private createForm() {
        this.form = this.fb.group({
            notNeeded: [this.store.selectSnapshot(PermitTeamSelectors.fireWatcherNotNeeded), Validators.requiredTrue]
        });
    }
}
