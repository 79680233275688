<ng-container *transloco="let t; read:'certificate'">
    <!-- Any data loaded from backend -->
    <ng-container *ngIf="cardDataLoaded$ | async else noData">
        <!-- Confirmed from backend to have a certificate -->
        <ng-container *ngIf="hasCard$ | async else noCertificate">
            <!-- Card is loaded -->
            <ng-container *ngIf="card$ | async as card">
                <!-- Has certificate but is offline. Show a message when it was last updated -->
                <div *ngIf="isOffline$ | async" class="info-message">
                    {{ t("offline", { updateDate: card.metadata.date | haDate: "dateTimeLongMonthNoYear" }) }}
                </div>

                <!-- The certificate -->
                <div class="card-preview">
                    <div class="card-preview__card card-preview__card--front">
                        <certificate-card-face [card]="card"></certificate-card-face>
                    </div>
                    <div class="card-preview__text">{{ t('qrClickOrScan') }}</div>
                    <div class="card-preview__card card-preview__card--back">
                        <certificate-card-face-back [card]="card"></certificate-card-face-back>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>

    <!-- No data loaded at all from backend. Notice that all certificate data is cleard on logout. So this is not uncommon. -->
    <ng-template #noData>
        <!-- No data loaded at all from backend and offline -->
        <div *ngIf="isOffline$ | async" class="info-message">{{ t("noCertificateDataOffline") }}</div>
    </ng-template>

    <!-- Confirmed from backend to have NO certificate -->
    <ng-template #noCertificate>
        <!-- online -->
        <div *ngIf="isOnline$ | async" class="info-message">{{ t("noCertificate") }}</div>
        <!-- offline -->
        <div *ngIf="isOffline$ | async" class="info-message">{{ t("noCertificateOffline") }}</div>
    </ng-template>
</ng-container>
