<div class="team-members" *transloco="let t; read:'permit.team'">
    <h2 class="team-members__heading" [innerHTML]="t('heading')"></h2>

    <ng-container *ngIf="permitTeam$ | async as team">
        <div class="team-members__role">
            <h3 class="team-members__role__heading" [innerHTML]="t('roles.permitissuer')"></h3>

            <div class="team-members__role__person-list">
                <div *ngIf="team.permitIssuer" class="team-members__role__person-list__item nohover">
                    <div class="team-members__role__person-list__item__text">{{team.permitIssuer.firstName}} {{team.permitIssuer.lastName}}</div>
                </div>
            </div>

            <div *ngIf="team.permitIssuer && (isPremium$ | async) === false" class="team-members__role__information"
                 [innerHtml]="t('permitIssuerSelectedByClient')"></div>
        </div>

        <!-- Hotworkers -->
        <div class="team-members__role">
            <h3 class="team-members__role__heading" [innerHTML]="t('roles.hotworker')"></h3>
            <ng-container [ngTemplateOutlet]="item" [ngTemplateOutletContext]="{teamMembers: team.hotWorkers, personRole: PersonRole.HotWorker, isDemo: isDemo$ | async}">
            </ng-container>
        </div>

        <!-- Fire Watchers -->
        <div class="team-members__role">
            <h3 class="team-members__role__heading" [innerHTML]="t('roles.firewatcher')"></h3>
            <div class="team-members__role__person-list">
                <div *ngFor="let teamMember of team.fireWatchers" class="team-members__role__person-list__item" [class.isdemo]="(isDemo$ | async) === true"
                     (click)="removePerson(teamMember, PersonRole.FireWatcher)">
                    <div class="team-members__role__person-list__item__text">{{teamMember.firstName}} {{teamMember.lastName}}</div>
                    <div *ngIf="(isDemo$ | async) === false" class="team-members__role__person-list__item__delete"></div>
                </div>
                <div *ngIf="team.fireWatchersNotNeeded && (!team.fireWatchers || team.fireWatchers.length <= 0)" class="team-members__role__person-list__item"
                     (click)="setFireWatcherNeeded()">
                    <div class="team-members__role__person-list__item__text">{{t('noFireWatcherNeeded')}}</div>
                </div>
                <div *ngIf="(isDemo$ | async) === false" class="team-members__role__person-list__add" (click)="add(PersonRole.FireWatcher)"></div>
            </div>
        </div>

        <!-- Post fire Watchers -->
        <div class="team-members__role">
            <h3 class="team-members__role__heading" [innerHTML]="t('roles.postfirewatcher')"></h3>
            <ng-container [ngTemplateOutlet]="item" [ngTemplateOutletContext]="{teamMembers: team.postFireWatchers, personRole: PersonRole.PostFireWatcher, isDemo: isDemo$ | async}">
            </ng-container>
        </div>
    </ng-container>

    <div class="team-members__actions">
        <button class="team-members__actions__back" type="button" (click)="goBack()">{{ t("back") }}</button>
        <button class="team-members__actions__continue" type="button" (click)="continue()" [class.team-members__actions__continue--disabled]="!canProceed">
            {{ t("next") }}
        </button>
    </div>

    <ng-template #item let-teamMembers="teamMembers" let-personRole="personRole" let-isDemo="isDemo">
        <div class="team-members__role__person-list">
            <div *ngFor="let teamMember of teamMembers" class="team-members__role__person-list__item" [class.isdemo]="isDemo === true"
                    (click)="removePerson(teamMember, personRole)">
                <div class="team-members__role__person-list__item__text">{{teamMember.firstName}} {{teamMember.lastName}}</div>
                <div *ngIf="isDemo === false" class="team-members__role__person-list__item__delete"></div>
            </div>
            <div *ngIf="isDemo === false" class="team-members__role__person-list__add" (click)="add(personRole)"></div>
        </div>
    </ng-template>
</div>
