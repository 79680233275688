import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseValidationResult } from "@ha/ui/forms";
import { Observable } from "rxjs";

import { PermitListItem } from "../models/permit-list-item.model";
import { PreparedPermitListItem } from "../models/prepared-permit-list-item.model";

@Injectable({ providedIn: "root" })
export class PermitListService {
    constructor(private httpClient: HttpClient) {}

    public getAll(): Observable<PermitListItem[]> {
        return this.httpClient.get<PermitListItem[]>("/api/PermitList/GetAll");
    }

    public getAllCompleted(): Observable<PermitListItem[]> {
        return this.httpClient.get<PermitListItem[]>("/api/PermitList/GetAllCompleted");
    }

    public getAllOngoing(): Observable<PermitListItem[]> {
        return this.httpClient.get<PermitListItem[]>("/api/PermitList/GetAllOngoing");
    }

    public getAllPlanned(): Observable<PermitListItem[]> {
        return this.httpClient.get<PermitListItem[]>("/api/PermitList/GetAllPlanned");
    }

    public getAllPrepared(): Observable<PreparedPermitListItem[]> {
        return this.httpClient.get<PreparedPermitListItem[]>("/api/PermitList/GetAllPrepared");
    }

    // Move this call to som other service?
    public claimPreparedPermit(permitId: number): Observable<BaseValidationResult> {
        return this.httpClient.put<BaseValidationResult>("/api/Permit/ClaimPreparedPermit", { permitId });
    }

    public removePlannedPermit(permitId: number): Observable<BaseValidationResult> {
        return this.httpClient.delete<BaseValidationResult>("/api/Permit/RemovePlannedPermit", { params: { permitId } });
    }
}
