<div class="root" *transloco="let t; read:'permit.prepare'">
    <form [formGroup]="form" (ngSubmit)="validate()">
        <div class="root__intro" [innerHTML]="t('intro')"></div>
        <div class="root__row">
            <ha-input #prefix class="root__row__input root__row__input--prefix" formControlName="prefix" [uppercaseOnly]="true" [label]="t('prefix')">
            </ha-input>
            <ha-input class="root__row__input root__row__input--work-code" formControlName="workCode" [uppercaseOnly]="true" [label]="t('workCode')"></ha-input>
        </div>

        <ng-container *ngIf="responseDetails$ | async as details">
            <div class="root__change-code" (click)="changeWorkCode()">{{ t("changeWorkCode") }}</div>

            <ng-container *ngIf="details.validWorkCode; else error">
                <div class="root__workplace-container">
                    <div class="root__workplace-container__column">
                        <div class="root__workplace-container__column__company-name">{{ details.companyName }}</div>
                        <div class="root__workplace-container__column__workplace">{{ details.workplace }}</div>
                        <div class="root__workplace-container__column__emergency-location">{{ details.emergencyLocation }}</div>
                    </div>
                </div>

                <div class="root__workplaces">
                    <ng-container *ngFor="let workplace of details.workplaces">
                        <div class="root__workplaces__item"
                             [class.root__workplaces__item--default]="workplace.isDefaultWorkplace && details.allowUnspecifiedWorkplace === false"
                             [class.root__workplaces__item--selected]="(selectedWorkplaceId$ | async) === workplace.id" (click)="toggleWorkplace(workplace.id)">
                            {{ workplace.name }}
                        </div>
                    </ng-container>
                </div>

                <div class="root__workplace-container__help-text" [innerHTML]="t('workplaceFoundHelpText')"></div>
            </ng-container>
        </ng-container>

        <ng-template #error>
            <div class="root__error-message" [innerHTML]="t('workplaceNotFound')"></div>
        </ng-template>

        <button *ngIf="(responseDetails$ | async) === undefined" class="root__button" type="submit">{{ t("getWorkplace") }}</button>

        <div class="root__actions">
            <button class="root__actions__back" type="button" routerLink="..">{{ t("cancel") }}</button>
            <button class="root__actions__continue" type="button" (click)="next()"
                    [class.root__actions__continue--disabled]="(validCode$ | async) === false || (selectedWorkplaceId$ | async) === undefined">
                {{ t("continue") }}
            </button>
        </div>
    </form>
</div>
