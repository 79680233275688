<ng-container *transloco="let t; read 'permit.list'">
    <a class="item" *ngIf="item" [routerLink]="routerLink" routerLinkActive="router-link-active">
        <div class="item__header">
            <div class="item__header__left">
                <div *ngIf="item.companyName" class="item__header__left__company" [innerHTML]="item.companyName"></div>
                <div class="item__header__left__workplace" [class.item__header__left__workplace--is-demo]="item.isDemo" [innerHTML]="fullPermitName(item)"></div>
                <div *ngIf="item.workplaceAdditionalInfo" class="item__header__left__company" [innerHTML]="item.workplaceAdditionalInfo"></div>
            </div>
            <div class="item__header__right">
                <div class="item__header__right__top">
                    <div class="item__header__right__top__remove" *ngIf="item.publicStatus === PermitPublicStatus.planned && isIssuer" (click)="remove($event)">
                    </div>
                    <div class="item__header__right__top__id" [innerHTML]="t('id', { id: item.id })"></div>
                    <div class="item__header__right__top__status" [class]="'item__header__right__top__status--' + item.publicStatus"></div>
                </div>
                <div class="item__header__right__bottom">
                    <div class="item__header__right__bottom__flammable" [class.item__header__right__bottom__flammable--not]="!item.isFlammableHotWork"></div>
                </div>
            </div>
        </div>
        <div class="item__content" [class.item__content--is-flammable]="!isNotFlammableAndCompleted" [class.item__content--is-demo]="isDemoAndCompleted">
            <div *ngIf="item.endDate || item.uniqueParticipantCount > 1" class="item__content__basic-info">
                <div *ngIf="item.endDate" class="item__content__basic-info__time">
                    <div class="icon"></div>
                    <div class="text">
                        {{ t(item.publicStatus === PermitPublicStatus.ongoing ? "validTo" : "expired", { endDate: item.endDate | haDate:"dateTimeStandard" }) }}
                    </div>
                </div>
                <div *ngIf="item.isFlammableHotWork && item.uniqueParticipantCount > 1" class="item__content__basic-info__participants">
                    <div class="icon"></div>
                    <div class="text">{{ t("participantCount", { participantCount: item.uniqueParticipantCount }) }}</div>
                </div>
            </div>

            <ng-container *ngIf="item.isFlammableHotWork">
                <div class="item__content__emergency">
                    <div class="item__content__emergency__number">
                        <div class="icon"></div>
                        <div class="text">{{ item.emergencyNumber }}</div>
                    </div>
                    <div *ngIf="item.emergencyLocation" class="item__content__emergency__location">
                        <div class="icon"></div>
                        <div class="text">{{ item.emergencyLocation }}</div>
                    </div>
                </div>
                <div class="item__content__role" [innerHTML]="item.roles?.length > 1 ? t('myRoles', { roles: roles }) : t('myRole', { role: roles })"></div>
            </ng-container>
        </div>
    </a>
</ng-container>
