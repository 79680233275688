import { Injectable } from "@angular/core";
import { Action, Actions, NgxsOnInit, ofActionSuccessful, Selector, State, StateContext } from "@ngxs/store";
import { EventTypes, PublicEventsService } from "angular-auth-oidc-client";
import { Observable } from "rxjs";
import { filter, tap } from "rxjs/operators";

import { UserEditStateActions } from "../../user-edit/state/user-edit.actions";
import { CurrentUserService } from "../services/current-user.service";
import { CurrentUser } from "./../models/current-user.model";
import { CurrentUserActions } from "./current-user.actions";
import { SettingsStateActions } from "./settings.actions";

export interface CurrentUserStateModel {
    currentUser: CurrentUser;
    noUserFound: boolean;
}

@Injectable()
@State<CurrentUserStateModel>({
    name: "currentUser",
    defaults: {
        currentUser: undefined,
        noUserFound: undefined,
    },
})
export class CurrentUserState implements NgxsOnInit {
    constructor(
        private currentUserService: CurrentUserService,
        private eventService: PublicEventsService,
        private actions$: Actions,
    ) {}

    @Selector()
    public static currentUser(state: CurrentUserStateModel): CurrentUser {
        return state?.currentUser;
    }

    @Selector([CurrentUserState.currentUser])
    public static firstName(currentUser: CurrentUser): string {
        return currentUser?.firstName;
    }

    @Selector([CurrentUserState.currentUser])
    public static fullName(currentUser: CurrentUser): string {
        return currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : undefined;
    }

    @Selector([CurrentUserState.currentUser])
    public static isImpersonated(currentUser: CurrentUser): boolean {
        return currentUser?.isImpersonated;
    }

    @Selector([CurrentUserState.currentUser])
    public static isHotworker(currentUser: CurrentUser): boolean {
        return currentUser?.isHotworker;
    }

    @Selector([CurrentUserState.currentUser])
    public static isEducationParticipant(currentUser: CurrentUser): boolean {
        return currentUser?.isEducationParticipant;
    }

    @Selector([CurrentUserState.currentUser])
    public static isExternal(currentUser: CurrentUser): boolean {
        return currentUser?.isExternal;
    }

    @Selector([CurrentUserState.currentUser])
    public static showLinkToAdmin(currentUser: CurrentUser): boolean {
        return (
            (currentUser &&
                (currentUser.isAdministrator ||
                    currentUser.isOrganizerAdministrator ||
                    currentUser.isInstructor ||
                    currentUser.isQualityController)) ||
            CurrentUserState.isImpersonated(currentUser)
        );
    }

    @Selector([CurrentUserState])
    public static noUserFound(state: CurrentUserStateModel): boolean | undefined {
        return state?.noUserFound;
    }

    public ngxsOnInit(ctx: StateContext<CurrentUserStateModel>): void {
        this.eventService
            .registerForEvents()
            .pipe(filter((notification) => notification.type === EventTypes.NewAuthenticationResult))
            .subscribe((x) => {
                if (x.value) {
                    ctx.dispatch(new CurrentUserActions.Get());
                }
            });

        this.actions$.pipe(ofActionSuccessful(UserEditStateActions.Save)).subscribe(() => {
            ctx.dispatch(new CurrentUserActions.Get());
        });
    }

    @Action(CurrentUserActions.Get)
    public get(ctx: StateContext<CurrentUserStateModel>): Observable<CurrentUser> {
        return this.currentUserService.get().pipe(tap((data) => {
            if (data) {
                ctx.patchState({
                    currentUser: data,
                    noUserFound: false
                });

                ctx.dispatch(new SettingsStateActions.ChangeLanguage(data.preferredLanguage));
            } else {
                ctx.setState(undefined);
                ctx.patchState({
                    noUserFound: true
                });
            }
        }));
    }
}
