<a class="puff" [class.puff--got-heading]="!!heading" [class]="layout ? 'puff--' + layout : ''" [routerLink]="urlFixed">
    <div class="puff__header">
        <div *ngIf="icon" class="puff__header__icon" [class]="'puff__header__icon--' + icon"></div>
        <div class="puff__header__heading" [innerHTML]="heading"></div>
    </div>
    <div class="puff__text" [innerHTML]="text">
    </div>
    <div class="puff__bottom">
        <div class="puff__bottom__line"></div>
        <div class="puff__bottom__arrow"></div>
    </div>
    <svg class="puff__shape" width="100%" viewBox="0 0 373 158" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="puff__shape__fill" d="M0 105.333C162.131 125.704 314.315 114.774 373 0V158H0V105.333Z" />
    </svg>
</a>
