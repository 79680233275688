import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { PermitStepActions } from "../../../../permit/states/permit-step.action";
import { PermitUserSelectors } from "../../../../shared/states/permit-user.selectors";
import { PermitWorkPlaceViewEnum } from "../../../models/permit-work-place-view.enum";
import { PermitWorkplaceActions } from "../../../states/permit-workplace.actions";
import { PermitWorkplaceSelectors } from "../../../states/permit-workplace.selectors";

@Component({
    selector: "pmt-workplace-start",
    templateUrl: "permit-workplace-start.component.html",
    styleUrls: ["permit-workplace-start.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PermitWorkplaceStartComponent {
    @Select(PermitWorkplaceSelectors.hasPremiumWorkplaceContainers)
    public hasPremiumWorkplaceContainers$: Observable<boolean>;

    @Select(PermitUserSelectors.canCreateBaseOrDemoPermits)
    public canCreateBaseOrDemoPermits$!: Observable<boolean>;

    constructor(private store: Store) { }

    public basePermit() {
        this.store.dispatch(new PermitWorkplaceActions.ChangeView(PermitWorkPlaceViewEnum.Base));
    }

    public premium() {
        this.store.dispatch(new PermitWorkplaceActions.ChangeView(PermitWorkPlaceViewEnum.Premium));
    }

    public goBack(): void {
        this.store.dispatch(new PermitStepActions.GoBackwards());
    }
}
